import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import stripes from "../images/layout/stripes.png"
import { WhiteButton } from "./layout/ReusableComponents"

const Section = styled.section`
  padding: 32px 12px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 350px);
  justify-content: center;
  grid-gap: 32px;
  max-width: 1210px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    grid-gap: 64px;
    padding: 64px 16px;
  }
`

const Box = styled.div`
  width: 350px;
  max-width: 100%;
  border-radius: 32px;
  overflow: hidden;
`

const TextBox = styled.div`
  background: ${({ theme }) => theme.blue};
  text-align: center;
  padding: 16px;
  position: relative;
  z-index: 1;

  ::before {
    content: "";
    background-image: url(${stripes});
    background-repeat: repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.2;
    z-index: -1;
  }
`
export default function ServicesGrid() {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "services" } }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              quality: 80
            )
          }
        }
      }
    }
  `)

  const allServices = [
    "dental implants",
    "sedation dentistry",
    "digital x-rays",
    "porcelain veneers",
    "same day crowns",
    "periodontal therapy",
    "invisible orthodontics",
    "cosmetic dentistry",
    "teeth whitening",
    "family care",
    "tooth colored fillings",
    "laser dentistry",
    "sleep apnea",
    "dental crowns",
  ]

  const images = data.allFile.nodes.map((node, i) => ({
    img: getImage(node),
    text: allServices[i],
    link: allServices[i].replace(/ /g, "-"),
  }))

  return (
    <Section>
      {images.map((service, i) => (
        <Box key={i}>
          <GatsbyImage image={service.img} alt={service.text} />
          <TextBox>
            <h3
              css={theme => css`
                margin: 0 0 32px;
                text-transform: capitalize;
                color: ${theme.white};
              `}
            >
              {service.text}
            </h3>
            <WhiteButton
              as={Link}
              to={`/services/${service.link}/`}
              css={css`
                margin: 0;
              `}
            >
              LEARN MORE
            </WhiteButton>
          </TextBox>
        </Box>
      ))}
    </Section>
  )
}
