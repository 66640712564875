import React from "react"
import Layout from "../components/layout/Layout"
import Seo from "../components/layout/Seo"
import ServicesGrid from "../components/ServicesGrid"

const IndexPage = () => (
  <Layout path="/services" servicesPage>
    <Seo seoTitle="Dental Services in Clearwater, FL | Chamberlain Dental" />
    <ServicesGrid />
  </Layout>
)

export default IndexPage
